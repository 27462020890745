import { pushAudioEvent } from "@exp/scripts/integration/gtm";
export { handleMileStoneTracking, handlePulseTracking, handleStartTracking };
const pulseIntervals = new Map();
function pushEvent(audio, gtmData, eventType, milestone) {
  pushAudioEvent({
    ...gtmData
  }, {
    duration: audio.duration,
    muted: audio.volume <= 0,
    milestone,
    currentTime: audio.currentTime * 1000
  }, eventType);
}
function handleMileStoneTracking(audio, gtmData, milestone, playerType) {
  const updatedPlayerType = playerType === "speech" ? "text-to-speech" : playerType;
  pushEvent(audio, {
    ...gtmData,
    event: `${updatedPlayerType} content milestone`
  }, "milestone", milestone);
}
function handlePulseTracking(audio, gtmData, shouldStart, playerType) {
  const updatedPlayerType = playerType === "speech" ? "text-to-speech" : playerType;
  if (shouldStart) {
    pulseIntervals.set(audio, setInterval(() => {
      pushEvent(audio, {
        ...gtmData,
        event: `${updatedPlayerType} content pulse`
      }, "pulse", null);
    }, 15000));
  } else {
    clearInterval(pulseIntervals.get(audio));
    pulseIntervals.delete(audio);
  }
}
function handleStartTracking(audio, gtmData, playerType) {
  const updatedPlayerType = playerType === "speech" ? "text-to-speech" : playerType;
  if (audio.currentTime < 1) {
    pushEvent(audio, {
      ...gtmData,
      event: `${updatedPlayerType} all start`
    }, "start", null);
  }
}